<div class="vh-100 overflow-auto">
  <div class="bg-light" *ngIf="!mobileView">
    <div class="bg-image h-100" >
      <img src="../../assets/img/profile_pic_home.jpg" class="img-fluid w-100" alt="profile_pic">
      <div class="mask" style="background-color: rgba(0, 0, 0, 0.3);">
      </div>
    </div>
    <div class="card bg-dark text-light m-5 position-absolute start-0 w-50" style="top: 5%;">
      <!--Card content-->
      <div class="card-body">

        <!--Title-->
        <h2 class="card-title text-wrap">Thomas Hauptvogel</h2>
        <h5 class="card-title text-wrap">Software Engineer</h5>
        <hr>

        <!--Text-->
        <span class="card-text d-inline-block text-wrap text-truncate"> Hi, I am Thomas. I'm a Software Engineer living in Dresden. If you want to know more about me, then lookup my CV or my other social network profiles.
        Otherwise feel free to explore this page and read my blog. :)
        </span>
        <div class="btn-group" role="group" aria-label="social buttons">
          <a
            mdbRipple
            class="btn btn-primary btn-lg"
            style="background-color: #333333;"
            href="https://github.com/haupth1992"
            role="button"
            ><i class="fab fa-github"> GitHub</i>
          </a>
          <a
            mdbRipple
            class="btn btn-primary btn-lg"
            style="background-color: #0082ca;"
            href="https://www.linkedin.com/in/thomas-hauptvogel-436408213/"
            role="button"
            ><i class="fab fa-linkedin-in"> LinkedIn</i>
          </a>
          <a
            mdbRipple
            class="btn btn-primary btn-lg"
            style="background-color: #026466;"
            href="https://www.xing.com/profile/Thomas_Hauptvogel7/cv"
            role="button"
            ><i class="fab fa-xing"> Xing</i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-light" *ngIf="mobileView">
    <div class="card bg-dark text-light m-5">
      <!--Card content-->
      <div class="card-body">
        <img
          src="../../assets/img/profile_pic_home.jpg"
          class="card-img"
          alt="Profile Picture"
        />
        <!--Title-->
        <div class="card-img-overlay text-dark text-break ms-1" style="width: 60%;">
          <h5 class="card-title text-wrap">Thomas Hauptvogel</h5>
          <h6 class="card-title text-wrap">Software Engineer</h6>
        </div>

        <hr>

        <!--Text-->
        <span class="card-text d-inline-block text-wrap text-truncate"> Hi, I am Thomas. I'm a Software Engineer living in Dresden. If you want to know more about me, then lookup my CV or my other social network profiles.
        Otherwise feel free to explore this page and read my blog. :)
        </span>
          <a
            mdbRipple
            class="btn btn-primary btn-lg btn-outline-light btn-floating m-1"
            style="background-color: #333333;"
            href="https://github.com/haupth1992"
            role="button"
            target="_blank"
            ><i class="fab fa-github"></i>
          </a>
          <a
            mdbRipple
            class="btn btn-primary btn-lg btn-outline-light btn-floating m-1"
            style="background-color: #0082ca;"
            href="https://www.linkedin.com/in/thomas-hauptvogel-436408213/"
            role="button"
            ><i class="fab fa-linkedin-in"></i>
          </a>
          <a
            mdbRipple
            class="btn btn-primary btn-lg btn-outline-light btn-floating m-1"
            style="background-color: #026466;"
            href="https://www.xing.com/profile/Thomas_Hauptvogel7/cv"
            role="button"
            ><i class="fab fa-xing"></i>
          </a>
      </div>
    </div>
  </div>
</div>
