import { Component, OnInit } from '@angular/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {

  items = ['TU Dresden', 'TU Dresden', 'DHBW Mannheim', 'Philipp-Melanchthon-Gymnasium Herzberg'];
  descs = ['Master of Science', 'Bachelor of Science', 'Bachelor of Science - Applied Computer Science', 'Graduation from high school'];
  dates = ['2017 - 2020', '2013 - 2017', '2012 - 2013', '2005 - 2012'];
  texts = [
            'Specialization in web technologies and data science. Focused in practical projects on smart home and human-computer-interaction.',
            'Specialization in software development and first contact with robotics.',
            'First insights to business and banking. Learning the essentials for developing on a mainframe with COBOL.',
            'High school degree with 1,6 as final grade and english & mathematics as advanced courses.'
          ]
  expandedIndex = 0;
  mobileView = false;

  constructor(public breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobileView = true;
        } else {
          this.mobileView = false;
        }
      });
  }

}
