<div class="vh-100 mb-3 overflow-auto">
  <div class="row row-cols-md-3 g-3 m-1" *ngIf="!mobileView; else mobileProjects">
    <div *ngFor="let project of projectData.projects; let index = index;" class="col">
      <div class="card text-white bg-dark h-100">
        <div class="row g-0">
          <div class="col d-flex align-items-center" *ngIf="!mobileView">
            <img
              src="{{ projectData.projects[index].img }}"
              alt="..."
              class="img-fluid p-3"
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">{{ projectData.projects[index].name }}</h5>
              <p class="card-text">{{ projectData.projects[index].desc }}</p>
              <a
                mdbRipple
                class="btn btn-primary btn-lg btn-outline-light btn-floating"
                style="background-color: #333333;"
                href="{{ projectData.projects[index].url }}"
                role="button"
                target="_blank"
                ><i class="fab fa-github"></i>
              </a>
              <p class="card-text text-light">
                <small class="text-muted">{{ projectData.projects[index].date }}</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #mobileProjects>
    <div *ngFor="let project of projectData.projects; let index = index;" class="col m-2">
      <div class="card text-white bg-dark h-100">
        <div class="row g-0">
          <div class="col d-flex align-items-center" *ngIf="!mobileView">
            <img
              src="{{ projectData.projects[index].img }}"
              alt="..."
              class="img-fluid p-3"
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">{{ projectData.projects[index].name }}</h5>
              <p class="card-text">{{ projectData.projects[index].desc }}</p>
              <hr>
              <div class="d-flex justify-content-start align-items-center">
                <a
                  mdbRipple
                  class="btn btn-primary btn-lg btn-outline-light btn-floating"
                  style="background-color: #333333;"
                  href="{{ projectData.projects[index].url }}"
                  role="button"
                  target="_blank"
                  ><i class="fab fa-github"></i>
                </a>
                <p class="card-text text-light ms-3">
                  <small class="text-muted">{{ projectData.projects[index].date }}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
