import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainContentComponent } from './main-content/main-content.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
//import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AboutMeComponent } from './about-me/about-me.component';
import { NavigationComponent } from './navigation/navigation.component';
import { BlogComponent } from './blog/blog.component';
import { ProjectsComponent } from './projects/projects.component';
import { EducationComponent } from './education/education.component';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { WorkExperienceComponent } from './work-experience/work-experience.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MdbCarouselModule }  from 'mdb-angular-ui-kit/carousel';
import { MdbCollapseModule} from 'mdb-angular-ui-kit/collapse';
import { FooterComponent } from './footer/footer.component';
@NgModule({
  declarations: [
    AppComponent,
    MainContentComponent,
    AboutMeComponent,
    NavigationComponent,
    BlogComponent,
    ProjectsComponent,
    EducationComponent,
    WorkExperienceComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MglTimelineModule,
    MatExpansionModule,
    CdkAccordionModule,
    MdbCarouselModule,
    MdbCollapseModule
    //MDBBootstrapModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
