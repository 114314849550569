<div class="timeline overflow-hidden">
  <div class="container left">
    <div class="date">2020 - present</div>
    <div class="content">
      <h3 class="text-white text-center">Otto Group Solution Provider (OSP)</h3>
      <h4 class="text-white text-center">Software Developer</h4>
      <ul class="list-group list-group">
        <li class="list-group-item bg-light">Development of web applications</li>
        <li class="list-group-item bg-light">Working in agile teams</li>
        <li class="list-group-item bg-light">Data modeling</li>
        <li class="list-group-item bg-light">Build and deploy cloud applications</li>
      </ul>
    </div>
  </div>
  <div class="container right">
    <div class="date">2017 - 2020</div>
    <div class="content">
      <h3 class="text-white text-center">Communardo Software GmbH</h3>
      <h4 class="text-white text-center">Student job as Software Developer</h4>
      <ul class="list-group list-group">
        <li class="list-group-item bg-light">Development of web applications</li>
        <li class="list-group-item bg-light">Automization of processes</li>
        <li class="list-group-item bg-light">Contact and project coordination with clients</li>
        <li class="list-group-item bg-light">Support</li>
        <li class="list-group-item bg-light">Mentoring of pupils at IT-Wintcamp and -Summercamp</li>
      </ul>
    </div>
  </div>
  <div class="container left">
    <div class="date">2014 - 2017</div>
    <div class="content">
      <h3 class="text-white text-center">Self employed</h3>
      <h4 class="text-white text-center">Service worker for events and catering</h4>
      <ul class="list-group list-group">
        <li class="list-group-item bg-light">Organization of tasks and team members</li>
      </ul>
    </div>
  </div>
  <div class="container right">
    <div class="date">2013</div>
    <div class="content">
      <h3 class="text-white text-center">Daimler AG</h3>
      <h4 class="text-white text-center">Assembly worker</h4>
      <ul class="list-group list-group">
        <li class="list-group-item bg-light">Shift work at the assembly line</li>
      </ul>
    </div>
  </div>
</div>
