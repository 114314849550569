import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import * as projectsJson from '../../assets/json/projects.json';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  expandedIndex = 0;
  mobileView = false;

  projectData = projectsJson;

  constructor(public breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    console.log(this.projectData.projects[0])
    this.breakpointObserver
      .observe(['(max-width: 1024px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobileView = true;
        } else {
          this.mobileView = false;
        }
      });
  }

}
