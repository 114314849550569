<!--Navbar-->
<nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">

  <!-- Navbar brand -->
  <div class="container-fluid">
    <button
      class="navbar-toggler"
      type="button"
      (click)="navbarToggler.toggle()"
      aria-controls="navbarToggler"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fas fa-bars"></i>
    </button>

    <a class="navbar-brand" href="">{{title}}</a>
    <!-- Collapsible wrapper -->
    <div
      class="collapse navbar-collapse"
      id="navbarSupportedContent"
      mdbCollapse
      #navbarToggler="mdbCollapse"
    >
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
                <a class="nav-link waves-light" href="">Home</a>
            </li>
          <!--
            <li class="nav-item">
                <a class="nav-link waves-light" href="blog">Blog</a>
            </li>
          -->
            <li class="nav-item">
                <a class="nav-link waves-light" href="projects">Projects</a>
            </li>
            <li class="nav-item">
                <a class="nav-link waves-light" href="about-me">About me</a>
            </li>
        </ul>
    </div>
  </div>
</nav>
<!--/.Navbar-->
