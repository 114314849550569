<div class="overflow-hidden" style="background-color: var(--bg-color-light)">
  <cdk-accordion class="accordion">
    <cdk-accordion-item
      *ngFor="let item of items; let index = index;"
      #accordionItem="cdkAccordionItem"
      class="accordion-item"
      role="button"
      tabindex="0"
      [attr.id]="'accordion-header-' + index"
      [attr.aria-expanded]="accordionItem.expanded"
      [attr.aria-controls]="'accordion-body-' + index">
      <div class="accordion-item-header" (click)="accordionItem.toggle()">
        {{ item }}
        <span *ngIf="!accordionItem.expanded && !mobileView" class="accordion-item-summary">
          {{ descs[index] }}
        </span>
        <span class="accordion-item-description">
          Click to {{ accordionItem.expanded ? 'close' : 'open' }}
        </span>
      </div>
      <div
        class="accordion-item-body"
        role="region"
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index">
        <div class="card" style="width: 100%;">
          <div class="card-body">
            <h5 class="card-title">{{ descs[index] }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{ dates[index] }}</h6>
            <p class="card-text">
              {{ texts[index] }}
            </p>
          </div>
        </div>
      </div>
    </cdk-accordion-item>
  </cdk-accordion>
</div>
